// Import React
import React, { Component, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'

// Framer Motion
// import { motion } from "framer-motion"

// style
import './WorkTiel.scss'

// componets




function WorkTiel(props) {

  // script

  // code dont run twice
  const DONT_RUN_TWICE = useRef(true)

  useEffect(() => {

    if (DONT_RUN_TWICE.current) {
      DONT_RUN_TWICE.current = false

      let cnts = document.querySelectorAll('.work-tiel__img-cnt')

      cnts.forEach(cnt => {

        let mask = cnt.querySelector('.work-tiel__img-mask')
        let elmWidth = cnt.offsetWidth
        let elmHeight = cnt.offsetHeight

        // if screen bigger than 600px then mouse controls mask otherwise it wil be automatic
        if (window.innerWidth > 600) {

          cnt.addEventListener('mouseover', () => {
            cnt.addEventListener('mousemove', (evt) => {

              let elmPosX = evt.layerX
              let elmPosY = evt.layerY

              let procentX = (elmPosX / elmWidth) * 100
              let procentY = (elmPosY / elmHeight) * 100


              mask.setAttribute('style', "-webkit-mask-position:" + procentX + "% " + procentY * 2 + "%;")

            })
          })
          
        }
        else {
          mask.setAttribute('style', "-webkit-mask-position: 50% 50%;")
        }


        cnt.addEventListener('mouseover', () => {
          cnt.addEventListener('mousemove', (evt) => {

            let elmPosX = evt.layerX
            let elmPosY = evt.layerY

            let procentX = (elmPosX / elmWidth) * 100
            let procentY = (elmPosY / elmHeight) * 100


            mask.setAttribute('style', "-webkit-mask-position:" + procentX + "% " + procentY * 2 + "%;")

          })
        })

      })

    }

  }, [])

  return (
    // <AnimatePresence initial={false} exitBeforeEnter>
    <Link className="work-tiel noise__anti" to={props.link}>
      <div className="work-tiel__dec">{props.title}</div>
      <div className="work-tiel__img-cnt">
        <picture>
          <source srcSet={props.imageAvif} />
          <source srcSet={props.imageWebp} />
          <img className="work-tiel__img" src={props.imagePng} alt="" />
        </picture>
        <div className="work-tiel__img-mask">
          <picture>
            <source srcSet={props.imageAvif} />
            <source srcSet={props.imageWebp} />
            <img className="work-tiel__img" src={props.imagePng} alt="" />
          </picture>
        </div>
      </div>
    </Link>
    // </AnimatePresence>
  );
}

export default WorkTiel;