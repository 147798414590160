// Import React
// import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import React, { Component, useEffect, useRef } from 'react';


// style
import './frontpage.scss'
import './workpages.scss'

// componets
import WorkTiel from '../componets/WorkTiel'
// import SmallProjects from '../componets/SmallProjects'
import ScrollToTop from '../componets/react-functions/ScrollToTop'

// images
// ---- Dansk skolecykling
import dscAvif from '../assets/img/work/dsc__tiel.avif'
import dscWebp from '../assets/img/work/dsc__tiel.webp'
import dscPng from '../assets/img/work/dsc__tiel.png'
// ---- Milestone Systems
import msAvif from '../assets/img/work/ms__tiel.avif'
import msWebp from '../assets/img/work/ms__tiel.webp'
import msPng from '../assets/img/work/ms__tiel.png'
// ---- meggies
import meggiesAvif from '../assets/img/work/meggies__tiel.avif'
import meggiesWebp from '../assets/img/work/meggies__tiel.webp'
import meggiesPng from '../assets/img/work/meggies__tiel.png'
// ---- Mixed Projects
import mixedAvif from '../assets/img/work/mixed__tiel.avif'
import mixedsWebp from '../assets/img/work/mixed__tiel.webp'
import mixedPng from '../assets/img/work/mixed__tiel.png'
// ---- Placeholder
import placeholder from '../assets/img/work/placeholder.png'


function Frontpage() {

    // script

    // code dont run twice
    const DONT_RUN_TWICE = useRef(true)

    useEffect(() => {

        if (DONT_RUN_TWICE.current) {
            DONT_RUN_TWICE.current = false

            // let cnts = document.querySelectorAll('.work-tiel__img-cnt')

            // cnts.forEach(cnt => {

            //     let mask = cnt.querySelector('.work-tiel__img-mask')
            //     let elmWidth = cnt.offsetWidth
            //     let elmHeight = cnt.offsetHeight


            //     cnt.addEventListener('mouseover', () => {
            //         cnt.addEventListener('mousemove', (evt) => {

            //             let elmPosX = evt.layerX
            //             let elmPosY = evt.layerY

            //             let procentX = (elmPosX / elmWidth) * 100
            //             let procentY = (elmPosY / elmHeight) * 100


            //             mask.setAttribute('style', "-webkit-mask-position:" + procentX + "% " + procentY * 2 + "%;")

            //         })
            //     })


            // })

        }

    }, [])

    return (
        <div className="frontpage">
            <ScrollToTop />
            <div className="work-page wrapper">

                <div className="intro noise__anti grid">
                    <h1 className="header__100px titles center">My best section of work</h1>
                    <p className="header__24px">
                        My creative frontend project merges captivating animations and imaginative designs, offering a visually stunning user experience. It seamlessly combines creativity with functionality for an immersive interface.
                    </p>
                </div>

                <section className="projects" id="work">
                    <WorkTiel title="Dansk Skolecykling" imagePng={dscPng} imageWebp={dscWebp} imageAvif={dscAvif} link="Dansk-skolecykling" />
                    <WorkTiel title="Milestone Systems" imagePng={msPng} imageWebp={msWebp} imageAvif={msAvif} link="Milestone-systems" />
                    <WorkTiel title="Meggies dog food" imagePng={meggiesPng} imageWebp={meggiesWebp} imageAvif={meggiesAvif} link="Meggies" />
                    <WorkTiel title="Fictional showcases" imagePng={mixedPng} imageWebp={mixedsWebp} imageAvif={mixedAvif} link="Mixed-projects" />
                </section>
            </div>
        </div>
    );
}

export default Frontpage;