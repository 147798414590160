// Import React
import React, { Component, useRef } from 'react';
import { useEffect } from 'react'

// style
import './PlayNQuiz.scss'

// componets

// ------- images -------
// hero
import circelImg from '../assets/img/playNquiz-mode/circel-quiz.svg'
import check from '../assets/img/playNquiz-mode/check.svg'
import mystery from '../assets/img/playNquiz-mode/mystery.svg'
import close from '../assets/img/playNquiz-mode/close.svg'



// ------- Json -------
const FUNFACTS_JSON = {
    "funfact": [
        { "type": "Funfact", "title": "I’m a pet lover!", "body": "I'm allergic to animals but I still dream of having a ...", "correct": "Dog", "wrong1": "Cat", "wrong2": "Dolphin" },
        { "type": "Funfact", "title": "I love snacks!", "body": "I love snacks but I have a favorite", "correct": "Popcorn", "wrong1": "Chocolate", "wrong2": "Nuts" },
        { "type": "Funfact", "title": "I love biking!", "body": "I’m a part of the danish national team can you guess which discipline", "correct": "BMX", "wrong1": "Road", "wrong2": "Mountain bike" }
    ]
}





function PlayNQuiz() {

    // script

    // So useeffect dont create elements twice
    const SHOULD_MAKE_ELEMENTS = useRef(true)

    useEffect(() => {



        // So useeffect dont create elements twice
        if (SHOULD_MAKE_ELEMENTS.current) {

            let innerWidth = window.innerWidth;

            SHOULD_MAKE_ELEMENTS.current = false

            let playQuiz = document.querySelector('.play-quiz')
            let points = document.querySelector('.play-quiz__points')

            let modal = document.querySelector('.play-quiz__modal')
            let modaClose = document.querySelector('.play-quiz__modal-close')
            let modalTitle = document.querySelector('.play-quiz__modal-title')
            let modalIframe = document.querySelector('.play-quiz__modal-iframe')
            let modalSub = document.querySelector('.play-quiz__modal-sub')
            let modalBody = document.querySelector('.play-quiz__modal-body')



            let funfactCounter = 0
            let projectCounter = 0
            // used to asign value when createt
            let flyingCircelsElmCounter = 1
            // used to count which circel is active
            let flyingCircelsCounter = 1
            // used to aisgn point checkmark
            let currentFlyingCircelType = ""
            let currentFlyingCircelNum = ""

            let windowHeight = window.innerHeight



            // funfact create points
            FUNFACTS_JSON.funfact.forEach(data => {
                creatPoints("funfact", funfactCounter)
                CreateFlyingCircels("funfact", funfactCounter)
                funfactCounter++
            });


            // function that creats points
            function creatPoints(type, arrayNum) {
                let button = document.createElement("button")
                button.classList.add('play-quiz__points-button')

                let icon = document.createElement("img")
                icon.classList.add('play-quiz__points-icon')
                icon.setAttribute("data-type", type)
                icon.setAttribute("data-num", arrayNum)
                icon.setAttribute("src", mystery)

                button.appendChild(icon)
                document.querySelector(".play-quiz__points-cnt").appendChild(button)
            }

            // function that creat circels
            function CreateFlyingCircels(type, arrayNum) {

                let circel = document.createElement("button")
                circel.classList.add('play-quiz__points-circel')
                circel.setAttribute("data-index", flyingCircelsElmCounter)
                circel.setAttribute("data-type", type)
                circel.setAttribute("data-num", arrayNum)

                let span = document.createElement("span")
                span.classList.add('play-quiz__points-circel-scale')

                let circelIcon = document.createElement("img")
                circelIcon.classList.add('play-quiz__points-circel-icon')
                circelIcon.setAttribute("src", circelImg)

                circel.appendChild(span)
                span.appendChild(circelIcon)
                document.querySelector(".play-quiz__circels-cnt").appendChild(circel)

                flyingCircelsElmCounter++
            }


            // function that activate the current circel
            function activateFlyingCircels() {
                let allCircels = document.querySelectorAll('.play-quiz__points-circel')
                let activeCircel


                switch (flyingCircelsCounter) {
                    case 1:
                        activeCircel = "1"
                        break;
                    case 2:
                        activeCircel = "2"
                        break;
                    case 3:
                        activeCircel = "3"
                }

                allCircels.forEach(circel => {
                    if (circel.dataset.index === activeCircel) {

                        circel.classList.add('play-quiz__points-circel--active')

                        // active circel content on click
                        circel.addEventListener('click', () => {
                            circel.classList.remove('play-quiz__points-circel--active')

                            let index = circel.dataset.index
                            let type = circel.dataset.type
                            let typeNum = circel.dataset.num


                            playQuiz.classList.add('play-quiz--active')
                            modal.classList.add('play-quiz__modal--active')


                            if (type === "funfact") {

                                // aisgn value for point checkmark
                                currentFlyingCircelType = type
                                currentFlyingCircelNum = typeNum

                                modalTitle.textContent = "Funfact"
                                modalSub.textContent = FUNFACTS_JSON.funfact[typeNum].title
                                modalBody.textContent = FUNFACTS_JSON.funfact[typeNum].body

                                createButtonsForModal(FUNFACTS_JSON.funfact[typeNum].correct, FUNFACTS_JSON.funfact[typeNum].wrong1, FUNFACTS_JSON.funfact[typeNum].wrong2)

                            }
                            if (type === "project") {

                                // aisgn value for point checkmark
                                currentFlyingCircelType = type
                                currentFlyingCircelNum = typeNum

                                console.log(FUNFACTS_JSON.project[typeNum]);
                                modal.classList.add('play-quiz__modal--active-project')
                                modaClose.classList.add('play-quiz__modal-close-project')

                                // show iframe
                                modalIframe.classList.add('play-quiz__modal-iframe--active')
                                modalTitle.classList.add('play-quiz__modal-title--project')
                                modalSub.classList.add('play-quiz__modal-sub--inactive')
                                modalBody.classList.add('play-quiz__modal-body--inactive')

                                modalTitle.textContent = "Project"
                                modalSub.textContent = ""
                                modalBody.textContent = ""
                                modalIframe.src = FUNFACTS_JSON.project[typeNum].linktoIframe


                            }


                        })

                    } else {
                        // active circels
                        circel.classList.remove('play-quiz__points-circel--active')
                    }
                });
            }

            activateFlyingCircels()


            // hide and show points menu i bottom of page "fixed"
            if (innerWidth >= 600) {
                document.addEventListener('mousemove', (evt) => {
                    if (evt.clientY >= windowHeight - 80) {
                        points.classList.add('play-quiz__points--active')
                    } else {
                        points.classList.remove('play-quiz__points--active')
                    }
                })
            }



            // function that creats buttons for modal
            function createButtonsForModal(btn1Corret, btn2, btn3) {
                let btnCnt = document.createElement("div")
                let button1 = document.createElement("button")
                let button2 = document.createElement("button")
                let button3 = document.createElement("button")
                let span1 = document.createElement("span")
                let span2 = document.createElement("span")
                let span3 = document.createElement("span")

                btnCnt.classList.add('play-quiz__modal-btn-cnt')
                button1.classList.add('play-quiz__modal-btn-correct')
                button1.classList.add('play-quiz__modal-btn')
                button2.classList.add('play-quiz__modal-btn')
                button3.classList.add('play-quiz__modal-btn')

                span1.textContent = btn1Corret
                span2.textContent = btn2
                span3.textContent = btn3

                button1.appendChild(span1)
                button2.appendChild(span2)
                button3.appendChild(span3)
                btnCnt.appendChild(button1)
                btnCnt.appendChild(button2)
                btnCnt.appendChild(button3)
                modal.appendChild(btnCnt)
            }


            let allPoints = document.querySelectorAll('.play-quiz__points-icon')

            // when close modal
            modaClose.addEventListener('click', () => {
                // hide modal
                modal.style.top = "50%"
                modal.style.left = "50%"
                playQuiz.classList.remove('play-quiz--active')
                modal.classList.remove('play-quiz__modal--active')

                // make points viseble for 5 s
                points.classList.add('play-quiz__points--active-closemodal')
                setTimeout(() => {
                    points.classList.remove('play-quiz__points--active-closemodal')
                }, 5000);


                allPoints.forEach(point => {
                    // change point from qustien sign to checkmark
                    if (point.dataset.type === currentFlyingCircelType && point.dataset.num === currentFlyingCircelNum) {
                        point.classList.add('play-quiz__points-icon--active')
                        setTimeout(() => {
                            point.setAttribute("src", check)
                        }, 1000);
                    }

                    // if buttons extis delete
                    // console.log(point.dataset.type);
                    // console.log(point.dataset.num);
                    console.log(currentFlyingCircelNum);
                    if (point.dataset.type === "funfact" && currentFlyingCircelType === "funfact" && point.dataset.num === currentFlyingCircelNum) {
                        let btnCtn = document.querySelector('.play-quiz__modal-btn-cnt')
                        btnCtn.remove()
                        // console.log('ok');
                    }

                });



                // prepare next circel
                flyingCircelsCounter++
                activateFlyingCircels()
            })

        }


    }, [])


    return (
        <section className="play-quiz">
            <div className="play-quiz__points">
                <div className="wrapper play-quiz__points-cnt"></div>
            </div>
            <div className="play-quiz__circels-cnt">
            </div>
            <div className="play-quiz__modal">
                <h2 className="play-quiz__modal-title titles header__70px"></h2>
                <h4 className="play-quiz__modal-sub header__24px"></h4>
                <p className="play-quiz__modal-body body__18px"></p>
                <iframe className="play-quiz__modal-iframe" src="" frameBorder="0"></iframe>
                <div className="play-quiz__modal-close">
                    <img src={close} alt="" />
                </div>
            </div>
        </section>
    );
}

export default PlayNQuiz;