// Import React
import React, { Component, useRef } from 'react';
import { useEffect } from 'react'



// Framer Motion


// style
import './SmallProjects2.scss'

// componets

// import images
// import ovalTranstion from '../assets/img/other-work-transtion.svg'
import ovalTranstion from '../assets/img/other-work-transtion--black.svg'
import ovalTranstionWhite from '../assets/img/other-work-transtion--white.svg'
import img1 from '../assets/img/small-projects/small--1.jpg'
import img2 from '../assets/img/small-projects/small--2.jpg'
import img3 from '../assets/img/small-projects/small--3.jpg'
import img4 from '../assets/img/small-projects/small--4.jpg'
import img5 from '../assets/img/small-projects/small--5.jpg'
import img6 from '../assets/img/small-projects/small--6.jpg'
import img7 from '../assets/img/small-projects/small--7.jpg'
import img8 from '../assets/img/small-projects/small--8.jpg'
import img9 from '../assets/img/small-projects/small--9.jpg'
import img10 from '../assets/img/small-projects/small--10.jpg'
import { computeHeadingLevel } from '@testing-library/react'



function SmallProjects() {


    // script

    // So useeffect dont make two listners
    const SHOULD_LISTEN = useRef(true)

    useEffect(() => {

        if (SHOULD_LISTEN.current) {
            SHOULD_LISTEN.current = false


            let grid = document.querySelector('.small-projects__breakout')
            let projets = document.querySelectorAll('.small-projects__grid-item')


            let xMover
            let yMover

            let xMax = (grid.offsetWidth / 4.1) / 50
            let yMax = (grid.offsetHeight / 4.1) / 50

            let screenWidth = window.innerWidth
            let screenHeight = window.innerHeight
            let screenDiagonal = Math.sqrt(Math.pow(screenWidth, 2) + Math.pow(screenHeight, 2))



            document.addEventListener('mousemove', (evt) => {


                let x = evt.clientX
                let y = evt.clientY

                let xProcent = x / screenWidth * 100
                let yProcent = y / screenHeight * 100





                // scaleing projects
                projets.forEach(projet => {

                    let projetW = projet.offsetWidth
                    let projetH = projet.offsetHeight

                    let projectXPostion = projet.getBoundingClientRect().x + (projetW / 2)
                    let projectYPostion = projet.getBoundingClientRect().y + (projetH / 2)


                    let deltaX = Math.max(x, projectXPostion) - Math.min(x, projectXPostion)
                    let deltaY = Math.max(y, projectYPostion) - Math.min(y, projectYPostion)

                    let dist = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2))


                    let distProcent = dist / screenDiagonal



                    projet.style.transform = `scale(${1 - (distProcent / 3)})`


                });


                // move grid

                if (evt.clientX < screenWidth / 2) {
                    // left
                    xMover = Math.abs((xProcent - 50) * xMax)
                } else {
                    // right
                    xMover = (xProcent - 50) * xMax * -1
                }

                if (evt.clientY < screenHeight / 2) {
                    // top
                    yMover = Math.abs((yProcent - 50) * yMax)
                } else {
                    // bottom
                    yMover = (yProcent - 50) * yMax * -1
                }

                grid.style.setProperty('transform', `translate(calc(-50% + ${xMover}px), calc(-50% + ${yMover}px))`)

            })

        }

    }, [])


    return (
        <div id="small-projects">
            <img src={ovalTranstion} alt="" width="100%" />
            <div className="small-projects">
                <div className="wrapper">
                    <h2 className="header__70px titles white-or-yellow noise__anti">Other Work</h2>
                    <p className="noise__anti">Move mouse to interact</p>
                </div>
                <div className="small-projects__breakout noise__anti">
                    <div className="small-projects__grid">
                        <div className="small-projects__grid-item small-projects__grid-item--1">
                            <img src={img1} alt="" />
                        </div>
                        <div className="small-projects__grid-item small-projects__grid-item--2">
                            <img src={img2} alt="" />
                        </div>
                        <div className="small-projects__grid-item small-projects__grid-item--3">
                            <img src={img4} alt="" />
                        </div>
                        <div className="small-projects__grid-item small-projects__grid-item--4">
                            <img src={img3} alt="" />
                        </div>
                        <div className="small-projects__grid-item small-projects__grid-item--5">
                            {/* <img src={img5} alt="" /> */}
                        </div>
                        <div className="small-projects__grid-item small-projects__grid-item--6">
                            <img src={img6} alt="" />
                        </div>
                        {/* <div className="small-projects__grid-item small-projects__grid-item--7">
                            <img src={img7} alt="" />
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <img src={ovalTranstionWhite} alt="" width="100%" /> */}
        </div>
    );
}

export default SmallProjects;
