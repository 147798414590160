// Import React
import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

// style
import './workpages.scss'

// componets
import WorkHeader from '../componets/WorkHeader'
import WorkTiel from '../componets/WorkTiel'
import ScrollToTop from '../componets/react-functions/ScrollToTop'


// ------- images -------
// hero
import heroPng from '../assets/img/work/meggies-hero.png'
import heroAvif from '../assets/img/work/meggies-hero.avif'
import heroWebp from '../assets/img/work/meggies-hero.webp'
// content
import fullPageWebp from '../assets/img/work/meggies-fullpage.webp'
import fullPageAvif from '../assets/img/work/meggies-fullpage.avif'
import fullPagePng from '../assets/img/work/meggies-fullpage.png'
import video1 from '../assets/img/work/meggies-loading-fp.mp4'
import video2 from '../assets/img/work/meggies-product.mp4'
import video3 from '../assets/img/work/meggies-subpage.mp4'
import video1PLaceholder from '../assets/img/work/meggies-loading-fp.png'
import video2PLaceholder from '../assets/img/work/meggies-product.png'
import video3PLaceholder from '../assets/img/work/meggies-subpage.png'
// ---- Mixed Projects
import mixedAvif from '../assets/img/work/mixed__tiel.avif'
import mixedsWebp from '../assets/img/work/mixed__tiel.webp'
import mixedPng from '../assets/img/work/mixed__tiel.png'




function DanskSkolecykling() {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    // Function to handle screen resize
    const handleResize = () => {
        setIsMobile(window.innerWidth < 600);
    };

    // useEffect to set up and clean up event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="work-page">
            <ScrollToTop />
            <WorkHeader year="2022" role="Full project" name="Meggies dog food" imageAvif={heroAvif} imageWebp={heroWebp} imagePng={heroPng} />

            {isMobile ? (
                // Mobile version

                <div>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" muted controls src={video1} poster={video1PLaceholder}></video>
                                </div>
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" muted controls src={video2} poster={video2PLaceholder}></video>
                                </div>
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" muted controls src={video3} poster={video3PLaceholder}></video>
                                </div>
                                <div className="work-page__image-full-page work-page__image-full-page--no-margin">
                                    <picture>
                                        <source srcSet={fullPageAvif} />
                                        <source srcSet={fullPageWebp} />
                                        <img className="work-header__img" src={fullPagePng} alt="" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <Link className="work-page__next-project" to='/Work/Mixed-projects'>
                            <div className="work-page__next-project-line"></div>
                            <div className="work-page__next-project-text">Next project</div>
                        </Link>
                    </section>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__next-project-large">
                                    <h5 className="header__24px">Did you enjoy?</h5>
                                    <h4 className="titles header__58px">See next project</h4>
                                </div>
                                <WorkTiel title="Fictional showcases" imagePng={mixedPng} imageWebp={mixedsWebp} imageAvif={mixedAvif} link="/Work/Mixed-projects" />
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                // desktop version

                <div>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" autoPlay muted loop src={video1}></video>
                                </div>
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" autoPlay muted loop src={video2}></video>
                                </div>
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" autoPlay muted loop src={video3}></video>
                                </div>
                                <div className="work-page__image-full-page work-page__image-full-page--no-margin">
                                    <picture>
                                        <source srcSet={fullPageAvif} />
                                        <source srcSet={fullPageWebp} />
                                        <img className="work-header__img" src={fullPagePng} alt="" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <Link className="work-page__next-project" to='/Work/Mixed-projects'>
                            <div className="work-page__next-project-line"></div>
                            <div className="work-page__next-project-text">Next project</div>
                        </Link>
                    </section>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__next-project-large">
                                    <h5 className="header__24px">Did you enjoy?</h5>
                                    <h4 className="titles header__58px">See next project</h4>
                                </div>
                                <WorkTiel title="Fictional showcases" imagePng={mixedPng} imageWebp={mixedsWebp} imageAvif={mixedAvif} link="/Work/Mixed-projects" />
                            </div>
                        </div>
                    </section>
                </div>
            )}

        </div>
    );
}

export default DanskSkolecykling;