// Import React
// import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import React, { Component, useEffect, useRef } from 'react';


// style
import './frontpage.scss'

// componets
import WorkTiel from '../componets/WorkTiel'
// import SmallProjects from '../componets/SmallProjects'
import SmallProjects2 from '../componets/SmallProjects2'
import ScrollToTop from '../componets/react-functions/ScrollToTop'

// images
// ---- Dansk skolecykling
import dscAvif from '../assets/img/work/dsc__tiel.avif'
import dscWebp from '../assets/img/work/dsc__tiel.webp'
import dscPng from '../assets/img/work/dsc__tiel.png'
// ---- Milestone Systems
import msAvif from '../assets/img/work/ms__tiel.avif'
import msWebp from '../assets/img/work/ms__tiel.webp'
import msPng from '../assets/img/work/ms__tiel.png'
// ---- meggies
import meggiesAvif from '../assets/img/work/meggies__tiel.avif'
import meggiesWebp from '../assets/img/work/meggies__tiel.webp'
import meggiesPng from '../assets/img/work/meggies__tiel.png'
// ---- Mixed Projects
import mixedAvif from '../assets/img/work/mixed__tiel.avif'
import mixedsWebp from '../assets/img/work/mixed__tiel.webp'
import mixedPng from '../assets/img/work/mixed__tiel.png'
// ---- Placeholder
import placeholder from '../assets/img/work/placeholder.png'


function Frontpage() {

  // script

  // code dont run twice
  const DONT_RUN_TWICE = useRef(true)

  useEffect(() => {

    if (DONT_RUN_TWICE.current) {
      DONT_RUN_TWICE.current = false

    }

  }, [])

  return (
    <div className="frontpage">
      <ScrollToTop />
      <div className="frontpage__gradient">
        <div className="frontpage__gradient--left"></div>
        <div className="frontpage__gradient--right"></div>
        <div className="frontpage__gradient--mixer-blue"></div>
        <div className="frontpage__gradient--mixer-gray"></div>
        <header className="frontpage__header">
          <h1 className="titles header__70px noise__anti"><span>Creative thinking Frontend Developer, <span>with strong passion for Vibrant and UI experiences</span></span></h1>
        </header>
      </div>
      <div className="wrapper">
        {/* <Scroll>
            
          </Scroll> */}
        <section className="projects" id="work">
          <WorkTiel title="Dansk Skolecykling" imagePng={dscPng} imageWebp={dscWebp} imageAvif={dscAvif} link="Work/Dansk-skolecykling" />
          <WorkTiel title="Milestone Systems" imagePng={msPng} imageWebp={msWebp} imageAvif={msAvif} link="Work/Milestone-systems" />
          <WorkTiel title="Meggies dog food" imagePng={meggiesPng} imageWebp={meggiesWebp} imageAvif={meggiesAvif} link="Work/Meggies" />
          <WorkTiel title="Fictional showcases" imagePng={mixedPng} imageWebp={mixedsWebp} imageAvif={mixedAvif} link="Work/Mixed-projects" />
        </section>
      </div>
      <SmallProjects2 />

    </div>
  );
}

export default Frontpage;