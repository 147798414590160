import React, { Component }  from 'react';

// style
import './WorkHeader.scss'

// componets


// images



function WorkHeader(props) {
    return (
        <header className="work-header">
            <div className="work-header__details wrapper">
                <div className="work-header__details-flex">
                    <p className="work-header__details-text noise__anti">Year: {props.year}</p>
                    <p className="work-header__details-text noise__anti">Role: {props.role}</p>
                </div>
                <h1 className="work-header__title noise__anti titles header__100px">{props.name}</h1>
            </div>
            <div className="work-header__img-cnt">
                <picture>
                    <source srcSet={props.imageAvif} />
                    <source srcSet={props.imageWebp} />
                    <img className="work-header__img noise__anti" src={props.imagePng} alt="" />
                </picture>
            </div>
        </header>
    );
  }
  
export default WorkHeader;