import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();


  const DONT_RUN_TWICE = useRef(true)

  useEffect(() => {

    if (DONT_RUN_TWICE.current) {
      DONT_RUN_TWICE.current = false

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);

    }

  }, [pathname]);

  return null;
}