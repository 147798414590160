import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();


    const DONT_RUN_TWICE = useRef(true)

    useEffect(() => {

        if (DONT_RUN_TWICE.current) {
            DONT_RUN_TWICE.current = false

            let readingTexts = document.querySelectorAll('.reading-animation')

            readingTexts.forEach(readingText => {

                let newText = ""
                let convertHTMLToString = readingText.innerHTML
                const readingTextsWords = convertHTMLToString.split(" ");

                readingTextsWords.forEach(readingTextsWord => {
                    newText += `<span>${readingTextsWord}</span> `
                });

                readingText.innerHTML = newText

            });



            // intersection observer options
            let optionsText = {
                root: null,
                rootMargin: "0px 0px",
                threshold: 0.1,
            };

            let callbackText = (entries, observer) => {
                entries.forEach((entry) => {

                    if (entry.isIntersecting) {
                        entry.target.classList.add('active')
                    }

                });
            };

            let observerText = new IntersectionObserver(callbackText, optionsText);


            //  get text cotainer and aply obsever
            let textCnt = document.querySelector('.reading-animation')

            observerText.observe(textCnt)


        }

    }, [pathname]);

    return null;
}