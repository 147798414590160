// imort react
import React, { Suspense, lazy, useEffect, useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, Transition, TransitionGroup } from 'react-transition-group';

// style
import './App.scss'

// componets
import Nav from './componets/Nav';
import Footer from './componets/Footer';
import PlayNQuiz from './componets/PlayNQuiz';

// pages
import Home from './pages/frontpage';
import Work from './pages/work';
import DanskSkolecykling from './pages/dansk-skolecykling';
import Milestone from './pages/milestone';
import Meggies from './pages/meggies';
import MixedProjects from './pages/mixed-projects';
import Contact from './pages/contact';
import About from './pages/about';
import NotFoundPage from './pages/notFoundPage';


// transtion






function App() {

  const location = useLocation();

  // code dont run twice
  const DONT_RUN_TWICE = useRef(true)

  useEffect(() => {

    if (DONT_RUN_TWICE.current) {
      DONT_RUN_TWICE.current = false


      // change selection color
      function changeSelectionColor() {

        let classIndex = 1;
        let maxClass = 25;
        let intervalId = null;

        // Funktion that start interval
        function startClassChange() {
          intervalId = setInterval(() => {

            document.body.className = `h-b-c-${classIndex}`
            // update classIndex, if classindex reach maxclass value then value is set to 1
            classIndex = (classIndex % maxClass) + 1;
          }, 180);
        }

        // Funktion that stops intevall
        function stopClassChange() {
          clearInterval(intervalId);
          intervalId = null;
        }

        // whenn user has selectet somthing
        document.addEventListener('selectionchange', () => {
          const selection = window.getSelection();
          if (selection && selection.toString().length > 0) {
            // If somthing is selected the start interval
            if (!intervalId) {
              startClassChange();
            }
          } else {
            // if nothing is selected then stop interval
            if (intervalId) {
              stopClassChange();
            }
          }
        });

      }

      changeSelectionColor()

    }

  }, [])

  return (
    <div className="theme">
      <Nav />


      {/* pages */}
      <TransitionGroup>
        <CSSTransition
          timeout={1200}
          classNames='page-transtion'
          key={location.key}
        >
          <Routes location={location}>
            <Route path="/" >
              <Route index element={<Home />} />

              {/* ---- Work pages ---- */}
              <Route path="Work">
                <Route path="" element={<Work />} />
                <Route path="Dansk-skolecykling" element={<DanskSkolecykling />} />
                <Route path="Milestone-systems" element={<Milestone />} />
                <Route path="Meggies" element={<Meggies />} />
                <Route path="Mixed-projects" element={<MixedProjects />} />
              </Route>

              {/* ---- Other pages ---- */}
              <Route path="About" element={<About />} />
              <Route path="Contact" element={<Contact />} />

              {/* ---- 404 page ---- */}
              <Route path="404" element={<NotFoundPage />} />
              <Route path="*" element={<NotFoundPage />} />

            </Route>
          </Routes>
        </CSSTransition>
      </TransitionGroup>

      <Footer />

      {/* </AnimatePresence> */}
      <PlayNQuiz />
      <div className="noise"></div>

    </div>
  );
}

export default App;
