// Import React
import React, { Component, useState, useEffect } from 'react';

import { Link } from 'react-router-dom'

// style
import './workpages.scss'

// componets
import WorkHeader from '../componets/WorkHeader'
import WorkTiel from '../componets/WorkTiel'
import ScrollToTop from '../componets/react-functions/ScrollToTop'


// ------- images -------
// hero
import heroPng from '../assets/img/work/dsc-hero.png'
import heroAvif from '../assets/img/work/dsc-hero.avif'
import heroWebp from '../assets/img/work/dsc-hero.webp'
// content
import placeholder from '../assets/img/work/placeholder.png'
import phone1Webp from '../assets/img/work/dsc-phone1.webp'
import phone1Png from '../assets/img/work/dsc-phone1.png'
import phone2Webp from '../assets/img/work/dsc-phone2.webp'
import phone2Png from '../assets/img/work/dsc-phone2.png'
import overviewAvif from '../assets/img/work/dsc-page-overview.avif'
import overviewWebp from '../assets/img/work/dsc-page-overview.webp'
import overviewPng from '../assets/img/work/dsc-page-overview.png'
import fullPageWebp from '../assets/img/work/dsc-fullpage.webp'
import fullPagePng from '../assets/img/work/dsc-fullpage.png'
import video from '../assets/img/work/dsc__video.mov'
import video2 from '../assets/img/work/dsc__video2.mov'
import video2Poster from '../assets/img/work/dcs__video2.png'
// ---- Milestone Systems
import msAvif from '../assets/img/work/ms__tiel.avif'
import msWebp from '../assets/img/work/ms__tiel.webp'
import msPng from '../assets/img/work/ms__tiel.png'


function DanskSkolecykling() {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    // Function to handle screen resize
    const handleResize = () => {
        setIsMobile(window.innerWidth < 600);
    };

    // useEffect to set up and clean up event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="work-page">
            <ScrollToTop />
            <WorkHeader year="2020" role="Frontend, design" name="Dansk Skolecykling" imageAvif={heroAvif} imageWebp={heroWebp} imagePng={heroPng} />

            {isMobile ? (
                // Mobile version

                <div>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__video-cnt">
                                    <video className="work-page__video" muted controls src={video} poster={heroPng}></video>
                                </div>
                                <div className="work-page__video-cnt">
                                    <video className="work-page__video" muted controls src={video2} poster={video2Poster}></video>
                                </div>
                                <div className="work-page__mobile">
                                    <picture>
                                        <source srcSet={phone1Webp} />
                                        <img className="work-header__img" src={phone1Png} alt="" />
                                    </picture>
                                </div>
                                <div className="work-page__mobile">
                                    <picture>
                                        <source srcSet={phone2Webp} />
                                        <img className="work-header__img" src={phone2Png} alt="" />
                                    </picture>
                                </div>
                                <div className="work-page__image-full">
                                    <picture>
                                        <source srcSet={overviewAvif} />
                                        <source srcSet={overviewWebp} />
                                        <img className="work-header__img" src={overviewPng} alt="" />
                                    </picture>
                                </div>
                                <div className="work-page__image-full-page work-page__image-full-page--no-margin">
                                    <picture>
                                        <source srcSet={fullPageWebp} />
                                        <img className="work-header__img" src={fullPagePng} alt="" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <Link className="work-page__next-project" to='/work/Milestone-systems'>
                            <div className="work-page__next-project-line"></div>
                            <div className="work-page__next-project-text">Next project</div>
                        </Link>
                    </section>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__next-project-large">
                                    <h5 className="header__24px">Did you enjoy?</h5>
                                    <h4 className="titles header__58px">See next project</h4>
                                </div>
                                <WorkTiel title="Milstone Systems" imagePng={msPng} imageWebp={msWebp} imageAvif={msAvif} link="/Work/Milestone-systems" />
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                // Desktop version

                <div>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__video-cnt">
                                    <video className="work-page__video" muted autoPlay loop src={video}></video>
                                </div>
                                <div className="work-page__mobile">
                                    <picture>
                                        <source srcSet={phone1Webp} />
                                        <img className="work-header__img" src={phone1Png} alt="" />
                                    </picture>
                                </div>
                                <div className="work-page__mobile">
                                    <picture>
                                        <source srcSet={phone2Webp} />
                                        <img className="work-header__img" src={phone2Png} alt="" />
                                    </picture>
                                </div>
                                <div className="work-page__video-cnt">
                                    <video className="work-page__video" muted autoPlay loop src={video2}></video>
                                </div>
                                <div className="work-page__image-full">
                                    <picture>
                                        <source srcSet={overviewAvif} />
                                        <source srcSet={overviewWebp} />
                                        <img className="work-header__img" src={overviewPng} alt="" />
                                    </picture>
                                </div>
                                <div className="work-page__image-full-page work-page__image-full-page--no-margin">
                                    <picture>
                                        <source srcSet={fullPageWebp} />
                                        <img className="work-header__img" src={fullPagePng} alt="" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <Link className="work-page__next-project" to='/work/Milestone-systems'>
                            <div className="work-page__next-project-line"></div>
                            <div className="work-page__next-project-text">Next project</div>
                        </Link>
                    </section>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__next-project-large">
                                    <h5 className="header__24px">Did you enjoy?</h5>
                                    <h4 className="titles header__58px">See next project</h4>
                                </div>
                                <WorkTiel title="Milstone Systems" imagePng={msPng} imageWebp={msWebp} imageAvif={msAvif} link="/Work/Milestone-systems" />
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </div>
    );
}

export default DanskSkolecykling;
