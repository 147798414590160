// Import React
// import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import React, { Component, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'


// style
import './Footer.scss'

// social icons
import ig from '../assets/img/instgram.svg'
import lin from '../assets/img/linkedin.svg'
import email from '../assets/img/email.svg'
import footerGraphic from '../assets/img/footer-graphic.svg'
import arrowRight from '../assets/img/arrow--right.svg'


function Footer() {

    // script

    // code dont run twice
    const DONT_RUN_TWICE = useRef(true)

    useEffect(() => {

        if (DONT_RUN_TWICE.current) {
            DONT_RUN_TWICE.current = false

        }

    }, [])

    return (
        <footer className="footer noise__anti">
            <div className="wrapper">
                <div className="footer__cnt">
                    <div className="footer__contact">
                        <a href="mailto:js@jakobsardag.dk">
                            <h2 className='titles header__70px'>Reach out!</h2>
                            <i><img src={arrowRight} alt="" /></i>
                        </a>
                    </div>
                    <div className="footer__links">
                        <div className="footer__links">
                            <div className="header__24px">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/Work">Work</Link></li>
                                    <li><Link to="/About">About</Link></li>
                                    <li><Link to="/Contact">Contact</Link></li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li><Link to="Work/Dansk-skolecykling">Dansk Skolecykling</Link></li>
                                    <li><Link to="Work/Milestone-systems">Milestone Systems</Link></li>
                                    <li><Link to="Work/Meggies">Meggies dog food</Link></li>
                                    <li><Link to="Work/Mixed-projects">Fictional showcases</Link></li>
                                    {/*  */}
                                    <ul>
                                        <li>Front-end development</li>
                                        <li>Digital design</li>
                                        <li>UI / UX</li>
                                        <li>Prototyping</li>
                                        <li className='footer__spacer'>Webflow</li>
                                        <li>Sketch / Figma</li>
                                        <li>Adobe suite</li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                        <div className="footer__socials">
                            <ul>
                                <li><a target='_blank' href="https://www.linkedin.com/in/jakob-s-07075616a/"><i><img src={lin} alt="" /></i></a></li>
                                <li><a target='_blank' href="https://www.instagram.com/jakobsardag.web/"><i><img src={ig} alt="" /></i></a></li>
                                <li><a target='_blank' href="mailto:js@jakobsardag.dk"><i><img src={email} alt="" /></i></a></li>
                            </ul>
                            <p>Copenhagen, Denmark</p>
                            <p><a href="mailto:js@jakobsardag.dk">&#106;&#115;&#64;&#106;&#97;&#107;&#111;&#98;&#115;&#97;&#114;&#100;&#97;&#103;&#46;&#100;&#107;</a></p>
                            <p><a href="tel:+4530185063">&#x2B;&#x34;&#x35;&#x20;&#x33;&#x30;&#x31;&#x38;&#x20;&#x35;&#x30;&#x36;&#x33;</a></p>
                        </div>
                    </div>
                    <div className="footer__infos">
                        <p>All content showcased in work tiels is owned by their respective creators.</p>
                        <p>&copy; Portfolio site created in 2024. ALL rights reserved</p>
                    </div>
                    <div className="footer__graphics">
                        <img src={footerGraphic} alt="" />
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;