// Import React
import { Link } from 'react-router-dom'
import React, { Component, useEffect, useRef } from 'react';

// style
import './contact.scss'

// componets
import ScrollToTop from '../componets/react-functions/ScrollToTop'



// ------- images -------




function Contact() {

    // code dont run twice
    const DONT_RUN_TWICE = useRef(true)

    useEffect(() => {

        // so useEffect dont run twice
        if (DONT_RUN_TWICE.current) {
            DONT_RUN_TWICE.current = false

            let counter = 0
            let fontSize = 80
            const ICEBRACKER = ["What's the best thing about Switzerland? I don't know but the flag is a big plus ", "Hello, here are two truths and a lie: ", "Helvetica and Times New Roman walk into a bar... 'Get out of here!' shouts the bartender. 'We don't serve your type.'", "My favourite food is: ", "Once upon a time, I ", "Hi, how are you? "]
            let updateMessageFontSize = null

            // form
            let form = document.querySelector('.contact-page__form')

            // inputs
            let message = document.querySelector('.contact-page__form-message')

            // buttons
            let icebraker = document.querySelector('.contact-page__button--icebraker')
            let restart = document.querySelector('.contact-page__button--restart')
            let send = document.querySelector('.contact-page__button--send')


            icebraker.addEventListener('click', icebrakerMessage)
            restart.addEventListener('click', restartMessage)
            send.addEventListener('click', sendMessage)

            // if on desktop
            if (window.innerWidth > 600) {

                message.addEventListener('input', updateMessageFontSize)


                // change fontsize based on input length
                function updateMessageFontSize() {
                    let messageLength = message.value.length


                    switch (messageLength) {
                        case 16:
                            fontSize = 80
                            break;
                        case 20:
                            fontSize = 79
                            break;
                        case 22:
                            fontSize = 78
                            break;
                        case 28:
                            fontSize = 77
                            break;
                        case 32:
                            fontSize = 76
                            break;
                        case 38:
                            fontSize = 75
                            break;
                        case 40:
                            fontSize = 73
                            break;
                        case 44:
                            fontSize = 70
                            break;
                        case 50:
                            fontSize = 67
                            break;
                        case 54:
                            fontSize = 64
                            break;
                        case 58:
                            fontSize = 61
                            break;
                        case 62:
                            fontSize = 58
                            break;
                        case 66:
                            fontSize = 55
                            break;
                        case 70:
                            fontSize = 52
                            break;
                        case 74:
                            fontSize = 49
                            break;
                        case 78:
                            fontSize = 46
                            break;
                        case 81:
                            fontSize = 43
                            break;
                    }

                    if (messageLength <= 16) {
                        fontSize = 80
                    } else if (messageLength >= 82) {
                        fontSize = 43
                    }

                    message.style.fontSize = fontSize + 'px'
                }

            }

            // restart message
            function restartMessage() {
                message.value = ""
                message.focus()
            }

            // make a icebraker
            function icebrakerMessage() {

                if (counter >= ICEBRACKER.length) {
                    counter = 0
                }

                message.value = ICEBRACKER[counter]
                message.innerHTML = ICEBRACKER[counter]
                updateMessageFontSize()
                counter++
            }

            // send message
            function sendMessage() {

                form.classList.add('contact-page__form--send')

                // reset message
                setTimeout(() => {
                    icebrakerMessage()
                }, 1500);

                // remove class
                form.addEventListener('animationend', () => {
                    form.classList.remove('contact-page__form--send')
                });


            }
        }


    }, [])


    function preventSubmit(e) {

        e.preventDefault()
    }


    return (
        <div className="contact-page wrapper">
            <ScrollToTop />
            <div className="contact-page__cta">
                <h1 className="titles header__100px">Ready to Start the Conversation?</h1>
                <p className="header__24px">Whether you have a question, a project idea, or just want to say hi, feel free to reach out by email or phone. I’m looking forward to hearing from you.</p>
                <div className="buttons">
                    <a href="mailto:js@jakobsardag.dk">&#106;&#115;&#64;&#106;&#97;&#107;&#111;&#98;&#115;&#97;&#114;&#100;&#97;&#103;&#46;&#100;&#107;</a>
                    <a href="tel:+4530185063">&#x2B;&#x34;&#x35;&#x20;&#x33;&#x30;&#x31;&#x38;&#x20;&#x35;&#x30;&#x36;&#x33;</a>
                </div>
            </div>
            <form className="contact-page__form noise__anti" method="get" onSubmit={preventSubmit}>
                <div className="contact-page__form-note"><i>Not functional</i></div>
                <div className="contact-page__button-cnt">
                    <button className="titles contact-page__button contact-page__button--icebraker">Ice breaker</button>
                    <button className="titles contact-page__button contact-page__button--restart">Restart</button>
                    <button className="titles contact-page__button contact-page__button--send">Send!</button>
                </div>
                <textarea className="titles contact-page__form-message" name="message" cols="30" rows="4" defaultValue="Hi, how are you?"></textarea>
            </form>
        </div>
    );
}

export default Contact;