// Import React
import React, { useEffect, useRef } from 'react';


// style
import './notFoundPage.scss'

// componets



// ------- images -------
import jakob from '../assets/img/portraits/jakob_wout-bcg-center.png'



function NotFoundPage() {

    // script


    const DONT_RUN_TWICE = useRef(true)

    useEffect(() => {

        if (DONT_RUN_TWICE.current) {

            DONT_RUN_TWICE.current = false


            let eyes = document.querySelectorAll('.not-found-page__roling-eye')
            let eyesInneer = document.querySelectorAll('.not-found-page__roling-eye--inner')

            let windowWidth = window.innerWidth * 1.9
            let windowHeight = window.innerHeight * 1.9

            // change value on resize
            window.addEventListener('resize', () => {
                windowWidth = window.innerWidth * 1.9
                windowHeight = window.innerHeight * 1.9
            })

            // move eyes
            window.addEventListener('mousemove', (win) => {
                let pageX = win.pageX
                let pageY = win.pageY

                let eyePosX = Math.round((pageX / windowWidth) * 100)
                let eyePosY = Math.round((pageY / windowHeight) * 100)

                eyes.forEach(eye => {
                    eye.style.left = eyePosX + 25 + "%"
                    eye.style.top = eyePosY + 25 + "%"
                });

                eyesInneer.forEach(eyeInner => {
                    eyeInner.style.left = eyePosX + 25 + "%"
                    eyeInner.style.top = eyePosY + 25 + "%"
                });

            })
        }



    }, [])


    return (
        <div className="not-found-page">
            <div className="not-found-page__roling-eyes-block">
                <div className="not-found-page__go-back">
                    <h1 className="header__58px" >Something went wrong!</h1>
                    <a href="/">Back to homepage</a>
                </div>
                <div className="not-found-page__roling-eyes-img-cnt">
                    <img className="not-found-page__roling-eyes-img noise__anti" src={jakob} alt="" />
                </div>
                <div className="not-found-page__roling-eyes-cnt noise__anti">
                    <div className="not-found-page__roling-eye-outer">
                        <div className="not-found-page__roling-eye not-found-page__roling-eye--1">
                            <div className="not-found-page__roling-eye--inner"></div>
                        </div>
                    </div>
                    <div className="not-found-page__roling-eye-outer">
                        <div className="not-found-page__roling-eye not-found-page__roling-eye--2">
                            <div className="not-found-page__roling-eye--inner"></div>
                        </div>
                    </div>
                </div>
                <h2 className="not-found-page__404">404</h2>
            </div>
        </div>
    );
}

export default NotFoundPage;