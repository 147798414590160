// Import React
import React, { Component, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'


// style
import './Nav.scss';


// social icons
import ig from '../assets/img/instgram.svg'
import lin from '../assets/img/linkedin.svg'
import email from '../assets/img/email.svg'


function Nav() {

    // script

    // code dont run twice
    const DONT_RUN_TWICE = useRef(true)


    useEffect(() => {

        // so useEffect dont run twice
        if (DONT_RUN_TWICE.current) {

            DONT_RUN_TWICE.current = false

            let mobileBurgerIcon = document.querySelector('.nav__menu-mobile button')
            let mobileBurgerMenu = document.querySelector('.nav__menu-mobile-burger')
            let mobileBurgerMenuLinks = mobileBurgerMenu.querySelectorAll('.nav__menu-mobile-burger-links a')

            let checkbox = document.querySelector('.nav__menu-site-theme__switch-checkbox')
            let theme = document.querySelector('.theme')
            let nav = document.querySelector('.nav')

            let curYOffset = 0
            let prevYOffset = 0
            let scrollDirCounter = 0

            // burger menu
            mobileBurgerIcon.addEventListener('click', () => {
                mobileBurgerIcon.classList.toggle('active')
                mobileBurgerMenu.classList.toggle('active')

                // make body not scroll while menu is open
                theme.classList.toggle('no-scroll')
            })

            // if user click link
            mobileBurgerMenuLinks.forEach(link => {
                link.addEventListener('click', () => {
                    mobileBurgerIcon.classList.toggle('active')
                    mobileBurgerMenu.classList.toggle('active')

                    // make body not scroll while menu is open
                    theme.classList.toggle('no-scroll')
                })
            });

            // theme
            checkbox.addEventListener('change', () => {
                // theme.classList.toggle('theme--black')
                theme.classList.toggle('theme--blue')
            })

            document.addEventListener('scroll', evt => {
                curYOffset = window.pageYOffset

                if (curYOffset >= prevYOffset && curYOffset >= 150) {
                    // nav iactive scroll down and under 150
                    nav.classList.add('nav__hide')
                    scrollDirCounter = 0
                }
                if (curYOffset <= 150) {
                    // nav active if scroll above 150
                    nav.classList.remove('nav__hide')
                }

                prevYOffset = curYOffset

            })

        }


    }, [])

    return (
        <nav className="nav">
            <div className="nav__inner">
                <div className="nav__title">
                    <Link to='/'>
                        <h2>Jakob Sardag</h2>
                        <h2 className="nav__title-opc">Digital designer <br />
                            &#38; frontend developer
                        </h2>
                    </Link>
                </div>
                <div className="nav__menu-all">
                    <div className="nav__menu-site-theme">
                        <label className="nav__menu-site-theme__switch">
                            <input className="nav__menu-site-theme__switch-checkbox" type="checkbox" />
                            <div className="nav__menu-site-theme__switch-cnt">
                                <span className="nav__menu-site-theme__slider"></span>
                            </div>
                            <p className="nav__menu-site-theme__quote">
                                <span className="nav__menu-site-theme__quote--black">You know what it is black and yellow</span>
                                <span className="nav__menu-site-theme__quote--blue">I'm blue daba dee daba die</span>
                            </p>
                        </label>
                    </div>
                    <div className="nav__menu-desktop">
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/Work">Work</Link>
                            </li>
                            <li>
                                <Link to="/About">About</Link>
                            </li>
                            <li>
                                <Link to="/Contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="nav__menu-mobile">
                        <button className='nav__menu-mobile-btn'>
                            <span className='nav__menu-mobile-btn-lines'>
                                <span className='nav__menu-mobile-btn-lines-center'></span>
                            </span>
                        </button>
                    </div>
                    <div className="nav__menu-mobile-burger">
                        <ul className="nav__menu-mobile-burger-links">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/Work">Work</Link>
                            </li>
                            <li>
                                <Link to="/About">About</Link>
                            </li>
                            <li>
                                <Link to="/Contact">Contact</Link>
                            </li>
                        </ul>
                        <div className="footer__socials">
                            <ul>
                                <li><a target='_blank' href="https://www.linkedin.com/in/jakob-s-07075616a/"><i><img src={lin} alt="" /></i></a></li>
                                <li><a target='_blank' href="https://www.instagram.com/jakobsardag.web/"><i><img src={ig} alt="" /></i></a></li>
                                <li><a target='_blank' href="mailto:js@jakobsardag.dk"><i><img src={email} alt="" /></i></a></li>
                            </ul>
                            {/* <p>Copenhagen, Denmark</p> */}
                            <p><a href="tel:+4530185063">&#x2B;&#x34;&#x35;&#x20;&#x33;&#x30;&#x31;&#x38;&#x20;&#x35;&#x30;&#x36;&#x33;</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Nav;