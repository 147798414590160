// Import React
import React, { Component, useEffect, useRef } from 'react';


// style
import './about.scss'
import '../componets/reading-animation.scss'

// componets
import WorkTiel from '../componets/WorkTiel'
import ScrollToTop from '../componets/react-functions/ScrollToTop'
import ReadingAnimation from '../componets/ReadingAnimation'

// images
import portraitAvif from '../assets/img/portraits/protrait-working.avif'
import portraitJpg from '../assets/img/portraits/protrait-working.jpg'
// ---- Dansk skolecykling
import dscAvif from '../assets/img/work/dsc__tiel.avif'
import dscWebp from '../assets/img/work/dsc__tiel.webp'
import dscPng from '../assets/img/work/dsc__tiel.png'



function Frontpage() {

    // script

    const DONT_RUN_TWICE = useRef(true)

    useEffect(() => {

        if (DONT_RUN_TWICE.current) {

            DONT_RUN_TWICE.current = false

            let optionsButtons = {}

            // if screen bigger than 600px then mouse controls mask otherwise it wil be automatic
            if (window.innerWidth > 600) {

                // intersection observer options
                optionsButtons = {
                    root: null,
                    rootMargin: "0px 0px -2%",
                    threshold: 0.8,
                };

            } else {
                // mobile

                // intersection observer options
                optionsButtons = {
                    root: null,
                    rootMargin: "0px 0px 0%",
                    threshold: 0.3,
                };
            }


            let callbackButtons = (entries, observer) => {
                entries.forEach((entry) => {

                    if (entry.isIntersecting) {
                        entry.target.classList.add('active')
                    }

                });
            };

            let observerButtons = new IntersectionObserver(callbackButtons, optionsButtons);


            //  get button cotainer and aply obsever
            let buttonCnt = document.querySelector('.personal-skills')

            observerButtons.observe(buttonCnt)

        }

    }, [])

    return (
        <div className="aboutpage">
            <ScrollToTop />
            <ReadingAnimation />
            <div className="wrapper">
                <div className="intro noise__anti">
                    <h1 className="header__100px titles center">
                        Jakob Sardag, <br />
                        Creative thinking <br />
                        Frontend Developer <br />
                        based in Copenhagen
                    </h1>
                    <picture className="intro__portrait">
                        <source srcSet={portraitAvif} />
                        <img src={portraitJpg} alt="Me working" />
                    </picture>
                </div>
                <div className="personal-skills noise__anti">
                    <p>
                        <strong draggable="true">UX / UI Design</strong>
                        <strong draggable="true">Web Development</strong>
                        <strong draggable="true">Digital design</strong>
                        <strong draggable="true">Prototyping</strong>
                    </p>
                    <p>
                        <strong draggable="true">Sketch</strong>
                        <strong draggable="true">Motion Graphics</strong>
                        <strong draggable="true">Webflow</strong>
                        <strong draggable="true">Adobe suite</strong>
                        <strong draggable="true">HTML - CSS - JS</strong>
                    </p>
                    <p>
                        <strong draggable="true">Food lover</strong>
                        <strong draggable="true">Dreamer</strong>
                        <strong draggable="true">Sports enthusiast</strong>
                        <strong draggable="true">BMX athlete</strong>
                    </p>
                </div>
                <div className="personal-description noise__anti">
                    <p className="titles header__58px reading-animation">I am a Creative thinking Frontend Developer specialized in animations, micro interactions and SVG.
                        I have a strong passion for Vibrant and user experiences.
                        <br /><br />
                        I’m always trying to make innovative websites, <br />the needs of a project are always my first priority.
                        <br /><br />
                        The most important thing for me is quality, <br />I never compromise on the quality of a website.
                    </p>
                </div>
                <section className="work-page__show noise__anti">
                    <div className="work-page__content">
                        <div className="work-page__grid">
                            <div className="work-page__next-project-large">
                                <h5 className="header__24px">Wanna see something cool?</h5>
                                <h4 className="titles header__58px">Selected Work</h4>
                            </div>
                            <WorkTiel title="Dansk Skolecykling" imagePng={dscPng} imageWebp={dscWebp} imageAvif={dscAvif} link="/Work/Dansk-skolecykling" />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Frontpage;