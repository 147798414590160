// Import React
import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

// style
import './workpages.scss'

// componets
import WorkHeader from '../componets/WorkHeader'
import WorkTiel from '../componets/WorkTiel'
import ScrollToTop from '../componets/react-functions/ScrollToTop'



// ------- images -------
// hero
import heroPng from '../assets/img/work/ms-hero.png'
import heroAvif from '../assets/img/work/ms-hero.avif'
import heroWebp from '../assets/img/work/ms-hero.webp'
// content
import fullPageAvif1 from '../assets/img/work/ms-fullpage1.avif'
import fullPageWebp1 from '../assets/img/work/ms-fullpage1.webp'
import fullPagePng1 from '../assets/img/work/ms-fullpage1.png'
import fullPageAvif2 from '../assets/img/work/ms-fullpage2.avif'
import fullPageWebp2 from '../assets/img/work/ms-fullpage2.webp'
import fullPagePng2 from '../assets/img/work/ms-fullpage2.png'
// ---- video
import video1 from '../assets/img/work/ms-video1.mov'
import video2 from '../assets/img/work/ms-video2.mov'
import video3 from '../assets/img/work/ms-video3.mov'
import video4 from '../assets/img/work/milestone-404.mp4'
// ---- video placeholders
import video1Placeholder from '../assets/img/work/ms-video1.webp'
import video2Placeholder from '../assets/img/work/ms-video2.webp'
import video3Placeholder from '../assets/img/work/ms-video3.webp'
import video4Placeholder from '../assets/img/work/milestone-404.png'
// ---- meggies
import meggiesAvif from '../assets/img/work/meggies__tiel.avif'
import meggiesWebp from '../assets/img/work/meggies__tiel.webp'
import meggiesPng from '../assets/img/work/meggies__tiel.png'




function Milestone() {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    // Function to handle screen resize
    const handleResize = () => {
        setIsMobile(window.innerWidth < 600);
    };

    // useEffect to set up and clean up event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="work-page">
            <ScrollToTop />
            <WorkHeader year="2018-2021" role="Frontend, design" name="Milestone Systems" imageAvif={heroAvif} imageWebp={heroWebp} imagePng={heroPng} />

            {isMobile ? (
                // Mobile version
                <div>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__video-cnt work-page__small-overlay">
                                    <video className="work-page__video" muted controls src={video1} poster={video1Placeholder}></video>
                                </div>
                                <div className="work-page__video-cnt work-page__small-overlay work-page__small-overlay--right">
                                    <video className="work-page__video" muted controls src={video2} poster={video2Placeholder}></video>
                                </div>
                                <div className="work-page__video-cnt work-page__small-overlay">
                                    <video className="work-page__video" muted controls src={video3} poster={video3Placeholder}></video>
                                </div>
                                <div className="work-page__video-cnt work-page__small-overlay">
                                    <video className="work-page__video" muted controls src={video4} poster={video4Placeholder}></video>
                                </div>
                                <div className="work-page__image-full-page work-page__small-overlay">
                                    <picture>
                                        <source srcSet={fullPageAvif1} />
                                        <source srcSet={fullPageWebp1} />
                                        <img className="work-header__img" src={fullPagePng1} alt="" />
                                    </picture>
                                </div>
                                <div className="work-page__image-full-page work-page__small-overlay work-page__small-overlay--right">
                                    <picture>
                                        <source srcSet={fullPageAvif2} />
                                        <source srcSet={fullPageWebp2} />
                                        <img className="work-header__img" src={fullPagePng2} alt="" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <Link className="work-page__next-project" to='/Work/Meggies'>
                            <div className="work-page__next-project-line"></div>
                            <div className="work-page__next-project-text">Next project</div>
                        </Link>
                    </section>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__next-project-large">
                                    <h5 className="header__24px">Did you enjoy?</h5>
                                    <h4 className="titles header__58px">See next project</h4>
                                </div>
                                <WorkTiel title="Meggies dog food" imagePng={meggiesPng} imageWebp={meggiesWebp} imageAvif={meggiesAvif} link="/Work/Meggies" />
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                //  computer

                <div>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__video-cnt work-page__small-overlay">
                                    <video className="work-page__video" autoPlay muted loop src={video1} poster={video1Placeholder}></video>
                                </div>
                                <div className="work-page__video-cnt work-page__small-overlay work-page__small-overlay--right">
                                    <video className="work-page__video" autoPlay muted loop src={video2} poster={video2Placeholder}></video>
                                </div>
                                <div className="work-page__video-cnt work-page__small-overlay">
                                    <video className="work-page__video" autoPlay muted loop src={video3} poster={video3Placeholder}></video>
                                </div>
                                <div className="work-page__video-cnt work-page__small-overlay">
                                    <video className="work-page__video" autoPlay muted loop src={video4} poster={video4Placeholder}></video>
                                </div>
                                <div className="work-page__image-full-page work-page__small-overlay">
                                    <picture>
                                        <source srcSet={fullPageAvif1} />
                                        <source srcSet={fullPageWebp1} />
                                        <img className="work-header__img" src={fullPagePng1} alt="" />
                                    </picture>
                                </div>
                                <div className="work-page__image-full-page work-page__small-overlay work-page__small-overlay--right">
                                    <picture>
                                        <source srcSet={fullPageAvif2} />
                                        <source srcSet={fullPageWebp2} />
                                        <img className="work-header__img" src={fullPagePng2} alt="" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <Link className="work-page__next-project" to='/Work/Meggies'>
                            <div className="work-page__next-project-line"></div>
                            <div className="work-page__next-project-text">Next project</div>
                        </Link>
                    </section>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__next-project-large">
                                    <h5 className="header__24px">Did you enjoy?</h5>
                                    <h4 className="titles header__58px">See next project</h4>
                                </div>
                                <WorkTiel title="Meggies dog food" imagePng={meggiesPng} imageWebp={meggiesWebp} imageAvif={meggiesAvif} link="/Work/Meggies" />
                            </div>
                        </div>
                    </section>
                </div>
            )}

        </div>
    );
}

export default Milestone;