// Import React
import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

// style
import './workpages.scss'

// componets
import WorkHeader from '../componets/WorkHeader'
import WorkTiel from '../componets/WorkTiel'
import ScrollToTop from '../componets/react-functions/ScrollToTop'


// ------- images -------
// hero
import heroPng from '../assets/img/work/mixed-hero.png'
import heroAvif from '../assets/img/work/mixed-hero.avif'
import heroWebp from '../assets/img/work/mixed-hero.webp'
// content
import video1 from '../assets/img/work/mixed-bond.mp4'
import video2 from '../assets/img/work/mixed-text.mp4'
import video3 from '../assets/img/work/mixed-bio.mp4'
import video4 from '../assets/img/work/motion-graphic.mp4'
// placeholders
import video1Placeholder from '../assets/img/work/mixed-bond.png'
import video2Placeholder from '../assets/img/work/mixed-text.png'
import video3Placeholder from '../assets/img/work/mixed-bio.png'
import video4Placeholder from '../assets/img/work/motion-graphic.jpg'
// ---- Dansk skolecykling
import dscAvif from '../assets/img/work/dsc__tiel.avif'
import dscWebp from '../assets/img/work/dsc__tiel.webp'
import dscPng from '../assets/img/work/dsc__tiel.png'




function DanskSkolecykling() {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    // Function to handle screen resize
    const handleResize = () => {
        setIsMobile(window.innerWidth < 600);
    };

    // useEffect to set up and clean up event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="work-page">
            <ScrollToTop />
            <WorkHeader year="2020-2022" role="Full project" name="Fictional showcases" imageAvif={heroAvif} imageWebp={heroWebp} imagePng={heroPng} />

            {isMobile ? (
                // Mobile version
                <div>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" muted controls src={video1} poster={video1Placeholder}></video>
                                </div>
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" muted controls src={video2} poster={video2Placeholder}></video>
                                </div>
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" muted controls src={video3} poster={video3Placeholder}></video>
                                </div>
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" muted controls src={video4} poster={video4Placeholder}></video>
                                </div>
                                <p className="work-page__content-owners">All content showcased here is owned by their respective creators.</p>
                            </div>
                        </div>
                        <Link className="work-page__next-project" to='/Work/Dansk-skolecykling'>
                            <div className="work-page__next-project-line"></div>
                            <div className="work-page__next-project-text">Next project</div>
                        </Link>
                    </section>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__next-project-large">
                                    <h5 className="header__24px">Did you enjoy?</h5>
                                    <h4 className="titles header__58px">See next project</h4>
                                </div>
                                <WorkTiel title="Dansk Skolecykling" imagePng={dscPng} imageWebp={dscWebp} imageAvif={dscAvif} link="/Work/Dansk-skolecykling" />
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                <div>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" autoPlay muted loop src={video1}></video>
                                </div>
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" autoPlay muted loop src={video2}></video>
                                </div>
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" autoPlay muted loop src={video3}></video>
                                </div>
                                <div className="work-page__video-cnt--small">
                                    <video className="work-page__video" autoPlay muted loop src={video4}></video>
                                </div>
                                <p className="work-page__content-owners">All content showcased here is owned by their respective creators.</p>
                            </div>
                        </div>
                        <Link className="work-page__next-project" to='/Work/Dansk-skolecykling'>
                            <div className="work-page__next-project-line"></div>
                            <div className="work-page__next-project-text">Next project</div>
                        </Link>
                    </section>
                    <section className="work-page__show wrapper noise__anti">
                        <div className="work-page__content">
                            <div className="work-page__grid">
                                <div className="work-page__next-project-large">
                                    <h5 className="header__24px">Did you enjoy?</h5>
                                    <h4 className="titles header__58px">See next project</h4>
                                </div>
                                <WorkTiel title="Dansk Skolecykling" imagePng={dscPng} imageWebp={dscWebp} imageAvif={dscAvif} link="/Work/Dansk-skolecykling" />
                            </div>
                        </div>
                    </section>
                </div>
            )}

        </div>
    );
}

export default DanskSkolecykling;